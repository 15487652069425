import React, { Component } from 'react';
import '../../app/App';
import './Sidenav.scss';
import { withTranslation } from 'react-i18next';
import PersonIcon from '@mui/icons-material/Person';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LocationSelection from './LocationSelection';
import { faParking, faChartBar, faExchangeAlt, faSignOutAlt, faClipboardList, faTachometerAlt, faCog, faBars, faEuroSign } from '@fortawesome/free-solid-svg-icons';
import { Grid, List, Divider, SwipeableDrawer, ListItemIcon, ListItemText, ListItem } from '@mui/material';
import { Link } from 'react-router-dom';
import i18n from 'utils/i18n';
import { getUserSettings, updateSettingsLocale } from 'utils/SettingsService';
import { getOwnerParkingSpaces, getParkingSpaces } from 'utils/ParkingService';
import SubSidenav from './sub/SubSidenav';
import { getUser } from 'utils/UsersService';
import { getConsent } from 'utils/ConsentService';
import { isMultiTouch } from 'utils/bridge';
import IconButton from '@mui/material/IconButton';
import Colors from '../../../constants/Colors';
import { HIDE_BOOKING, PARKING_GROUPS, SHOW_ANNOUNCEMENTS } from 'constants/Features';
import { featureEnabled } from 'utils/FeatureTogglerService';
import { ReactComponent as IconSwitch } from '../../../assets/icons/publicApp/icon_switch_horizontal.svg';
import { getLocales } from 'utils/TranslationsService';

class Sidenav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      left: false,
      lng: 'lt-LT',
      locales: [],
      openDialog: false,
      name: '',
      showItem: {
        'spaces.Title': false,
        'groups.groups': false,
      },
      tenantId: null,
    };
    this.navList = [
      {
        title: 'dashboard.Title',
        path: '/dashboard',
        icon: faTachometerAlt,
        userVisible: true,
        adminVisible: true,
      },
      {
        title: 'exchanges.Title',
        path: '/exchanges',
        icon: faExchangeAlt,
        adminVisible: true,
        userVisible: true,
        isHidden: props.enabledFeatures[HIDE_BOOKING] ?? false,
      },
      {
        title: 'reports.Title',
        icon: faChartBar,
        adminVisible: true,
        subItems: [
          {
            title: 'reports.Statistic',
            path: '/reports',
          },
          {
            title: 'reports.Action',
            path: '/reports/action',
          },
        ],
      },
      {
        title: 'administration.Title',
        icon: faClipboardList,
        adminVisible: true,
        subItems: [
          {
            title: 'users.Title',
            path: '/users',
          },
          {
            title: 'settings.SystemSettings',
            path: '/settings',
            icon: faCog,
            adminVisible: true,
            userVisible: true,
          },
          {
            title: 'parkinger.Rates',
            path: '/parkingRates',
            adminVisible: true,
            userVisible: true,
          },
          {
            title: 'administration.Territories',
            path: '/administration',
          },
          {
            title: 'spaces.Title',
            path: '/spaces',
            icon: faParking,
            adminVisible: true,
          },
          {
            title: 'administration.Consent',
            path: '/consent/edit',
          },
          {
            title: 'announcementsUi.Announcements',
            path: '/announcements',
            isHidden: !props?.enabledFeatures[SHOW_ANNOUNCEMENTS] ?? true,
          },
          {
            title: 'administration.Translations',
            path: '/translations',
          },
          {
            title: 'groups.groups',
            path: '/parkingGroups',
          },
          {
            title: 'administration.Customers',
            path: '/customers',
            isHidden: !props?.currentUser?.customerId ?? true,
          },
        ],
      },
      {
        title: 'administration.Consent',
        path: '/consent',
        icon: faClipboardList,
        adminVisible: true,
        userVisible: true,
      },
      {
        title: 'administration.Payments',
        path: '/payments',
        icon: faEuroSign,
        isHidden: !(props?.settings?.Customer?.BusinessPaymentsEnable?.value === 'true') ?? true,
        adminVisible: true,
        userVisible: true,
      },
    ];
  }

  checkConsent = (opened) => {
    if (!opened) return;

    getConsent()
      .then((val) => {
        if (!val) {
          this.props.setConsent(val);
          this.props.history.push('/consent');
        }
      })
      .catch((reason) => {
        // on next navigation will be checked
      });
  };

  postDeviceToken = (opened) => {
    if (!opened) return;
    if (!isMultiTouch()) return;
  };

  toggleNav = async (opened) => {
    this.checkConsent(opened);
    this.postDeviceToken(opened);
    if (opened) {
      await this.fetchLocales();
    }
    this.setState({ ...this.state, left: opened });
  };

  fetchLocales = async () => {
    try {
      const { data } = await getLocales();
      const locales = await data.items.map((e) => {
        return { key: e.key, lng: e.key.slice(0, 2) };
      });
      this.setState({ locales });
    } catch (e) {
      console.error(e);
    }
  };

  closeSidebar = () => {
    this.props.history.push({
      state: {
        openSideNav: false,
      },
    });
  };

  setActiveLang = async () => {
    try {
      const thisLang = localStorage.currentLng;
      const items = await getUserSettings(this.props.currentUser.id);
      const LocaleSettings = await items.find((i) => i.property === 'Locale');
      if (!LocaleSettings) {
        if (thisLang) {
          await updateSettingsLocale(thisLang, this.props.currentUser.id).catch((error) => console.error(error));
        } else if (!thisLang) {
          localStorage.setItem('currentLng', 'lt-LT');
          await i18n.changeLanguage('lt-LT');
        }
      } else {
        localStorage.setItem('currentLng', LocaleSettings.value);
        await i18n.changeLanguage(LocaleSettings.value);
      }
    } catch (e) {
      console.log(e);
    }
  };

  componentWillReceiveProps = async (props) => {
    const { location } = props;
    // this.openLocales();
    if (location && location.state && location.state.openSideNav) {
      await this.toggleNav(true);
    } else {
      await this.toggleNav(false);
    }
  };

  async componentDidMount() {
    this.setState({ lang: localStorage.currentLng });
    const tenantId = localStorage.getItem('tenantId');
    if (tenantId) {
      this.setState({ tenantId: tenantId });
      await this.setActiveLang();
      // Tikriname ar turi priskirtu vietu
      // https://crm.softra.lt/issues/22901
      if (this.props.currentUser.role === 'User') {
        let spaces = await getOwnerParkingSpaces(this.props.currentUser.id);
        if (!spaces.length) {
          this.navList = this.navList.filter((x) => x.title !== 'exchanges.History');
        }
      }

      if (this.props.isAdmin) {
        const spaces_ = await getParkingSpaces();
        if (spaces_ && spaces_.items && spaces_.items.length > 0 && spaces_.items[0].bookingLevel < 2) {
          const sp = this.state.showItem;
          sp['spaces.Title'] = true;
          this.setState({ showItem: sp });
        }

        const parking_groups = await featureEnabled(PARKING_GROUPS);
        if (parking_groups) {
          const sp_ = this.state.showItem;
          sp_['groups.groups'] = parking_groups;
          this.setState({ showItem: sp_ });
        }
      }

      const user = await getUser(this.props.currentUser.id);
      this.setState({ name: user.name });
    }
  }

  switchToPublic = async () => {
    sessionStorage.clear();
    const tenantId = localStorage.getItem('tenantId');
    localStorage.setItem('privateTenantId', tenantId);
    localStorage.setItem('userSite', 'switchToPublic');
    localStorage.removeItem('tenantId');
    this.setState({ tenantId: null });
    this.props.login(this.props.history).then(() => {
      this.props.history.push({ pathname: '/publicMap' });
    });
  };

  render() {
    const { t, currentUser, logout, isAdmin } = this.props;
    const { openDialog, lang, locales, name, showItem, tenantId } = this.state;
    const lng = i18n.languages[0];

    return (
      <SwipeableDrawer open={this.state.left} onClose={() => this.toggleNav(false)} onOpen={() => this.toggleNav(true)} disableSwipeToOpen={!tenantId}>
        <Grid>
          <Grid container direction="row" px={3} pt={0.6} alignItems="center" justifyContent="space-between">
            <Grid item>
              <IconButton edge="start" onClick={this.closeSidebar} aria-label="Menu">
                <FontAwesomeIcon icon={faBars} color={Colors.iconHead} />
              </IconButton>
            </Grid>
            <Grid paddingLeft="50px">
              <LocationSelection currentUser={currentUser} lng={lang} locales={locales} />
            </Grid>
          </Grid>
        </Grid>
        <Grid className="body-sidenav">
          <List sx={{ padding: 0 }}>
            <Divider />
            <Link
              className="ListItem"
              replace
              to={{
                pathname: '/user/' + currentUser?.id,
                state: { user: currentUser },
              }}
            >
              <ListItem spacing={3}>
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText>{t('users.MyProfile')}</ListItemText>
              </ListItem>
            </Link>
            <Divider />
            {this.navList.map(
              (e, i) =>
                !e.isHidden &&
                ((isAdmin && e.adminVisible) || (!isAdmin && e.userVisible)) &&
                (e.subItems ? (
                  <SubSidenav item={e} key={i} showItem={showItem}></SubSidenav>
                ) : (
                  <Link key={i} to={e.path} className="ListItem" replace>
                    <ListItem>
                      <ListItemIcon>
                        <FontAwesomeIcon icon={e.icon} />
                      </ListItemIcon>
                      <ListItemText>{t(e.title)}</ListItemText>
                    </ListItem>
                    <Divider />
                  </Link>
                ))
            )}
          </List>
        </Grid>
        <Grid className="footer-sidenav">
          <Divider sx={{ backgroundColor: '#ccc', height: '1px' }} />
          <Grid>
            <span id="switch-to-public" className="switchToPublic" onClick={this.switchToPublic}>
              <ListItem className='list-item-button'>
                <ListItemIcon>
                  <IconSwitch fill="#27348A" height="22px" width="22px" />
                </ListItemIcon>
                <ListItemText>{t('publicApp.PublicAccount')}</ListItemText>
              </ListItem>
            </span>
          </Grid>
          <Divider sx={{ backgroundColor: '#ccc', height: '1px' }} />
          <Grid>
            <span id="sign-out" className="SignOut" onClick={logout}>
              <ListItem className='list-item-button'>
                <ListItemIcon>
                  <FontAwesomeIcon icon={faSignOutAlt} color="#27348A" />
                </ListItemIcon>
                <ListItemText>{t('auth.Logout')}</ListItemText>
              </ListItem>
            </span>
          </Grid>
        </Grid>
      </SwipeableDrawer>
    );
  }
}
export default withTranslation()(Sidenav);
